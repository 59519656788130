<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="header-text">Согласование закупки №{{ purchase.id }}</h4>
        <span style="margin-right: 10px; font-size: 17px"
          ><b-icon icon="x-lg" type="submit" @click="$emit('showModal')"></b-icon
        ></span>
      </div>
      <div class="modal-body">
        <b-table
          v-if="purchase.histories"
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="purchase.histories"
          :fields="fields"
          small
        >
          <template #cell(role)="data">
            <span>
              {{ getRole(data.item.role) }}
            </span>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ new Date(data.item.created_at).toLocaleDateString() }}
            </span>
          </template>
          <template #cell(status)="data">
            <span>
              {{ status(data.item.status) }}
            </span>
          </template>
        </b-table>
        <button class="header__btn right-item default-btn float-right" @click="downlaod()">
          Скачать
        </button>
        <button
          v-if="purchase.status === 'questioned'"
          class="header__btn right-item default-btn modal__question-btn"
          @click="sendAnswer()"
        >
          Оставить ответ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    purchase: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "full_name",
          label: "ФИО",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "role",
          label: "Должность",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "created_at",
          label: "Дата согласования",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "comment",
          label: "Комментарий",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
      ],
    };
  },
  methods: {
    status(value) {
      if (value === "agreed") {
        return "Согласован";
      } else if (value === "consideration") {
        return "Рассмотрение";
      } else if (value === "questioned") {
        return "Запрошена доп. информация";
      } else if (value === "agreed_chief") {
        return "Согласовано старшим механиком";
      } else if (value === "agreed_analytic") {
        return "Согласовано аналитиком";
      } else if (value === "canceled") {
        return "Отменен";
      } else if (value === "completed") {
        return "Завершен";
      }
    },
    getRole(role) {
      switch (role) {
        case "chief_mechanic":
          return "Старший механик";
        case "accountant":
          return "Бухгалтер";

        case "director":
          return "Директор";

        default:
          return "";
      }
    },
    sendAnswer() {
      let comment = prompt("Оставьте комментарий", "");
      if (comment) {
        this.$api
          .post("/web/purchase/update/" + this.purchase.id, {
            comment: comment,
            user_id: this.$store.state.profileProducts.id,
            status: "answered",
          })
          .then(() => {
            this.$emit("search");
            this.$emit("showModal");
            this.$toast.success("Успешно");
          })
          .catch(() => {
            this.$toast.error("Что-то пошло не так, свяжитесь с администратором");
          });
      }
    },
    async downlaod() {
      await axios({
        method: "get",
        url: process.env.VUE_APP_API_URL + `/web/export-sheet?purchase_id=${this.purchase.id}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          "Cache-Control": "no-cache",
        },
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.modal-container {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
.modal__question-btn {
  width: 100%;
  background: #e0e9fa;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366d6;
  margin-right: 28px;
  margin-top: 20px;
}
</style>
